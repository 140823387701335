import { memo, FC } from 'react';
import Carousel from './Carousel';
import { Icons } from './Icons';
import { CardData, cardsData } from './cards-data';
import { BUCKETS } from './constants';
import { ANALYTICS_CONSTANTS, trackEDDL } from '../../../utilities/analytics';

interface IColdStartCarousel {
    bucketName: string;
}

const Card = memo(({ backgroundId, label, href }: CardData) => (
    <a href={href} className="cold-start__card" card-background-id={backgroundId} aria-label={label}>
        <div className="cold-start__label">
            {label === 'Start with a trade-in' ? <Icons.Tag /> : <Icons.MagnifyingGlass />}
            {label}
        </div>
    </a>
));

const ColdStartCarousel: FC<IColdStartCarousel> = ({ bucketName }) => {
    // Swap first two items for variation-b
    if (bucketName.toLowerCase() === BUCKETS.VARIATION_B) {
        const tempItem = cardsData[0];
        cardsData[0] = cardsData[1];
        cardsData[1] = tempItem;
    }

    const CTA_TITLE = 'Explore cars that fit your life';

    const handleOnClick = (url: string) => {
        // Analytics
        trackEDDL({
            event: ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA,
            linkDetails: {
                name: CTA_TITLE,
                position: 'Homepage',
            },
            search: {
                initiationMethod: `${CTA_TITLE}|Homepage`,
            },
        });

        // Redirect
        window.location.href = url;
    };

    return (
        <div id="cold-start-carousel-container" className="carousel--container">
            <div className="carousel--grid">
                <div className="carousel--title">
                    <h1>
                        Explore cars <br className="carousel--title-br" /> that fit your life
                    </h1>
                    <button onClick={() => handleOnClick('/cars/all')} title={CTA_TITLE}>
                        <Icons.Search />
                    </button>
                </div>
                <Carousel nextFocus="budget-calculator-section" name="cold-start">
                    {cardsData.map(({ backgroundId, label, href }: CardData, i: number) => (
                        <Card key={i} backgroundId={backgroundId} label={label} href={href} />
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

Card.displayName = 'Card';

export default ColdStartCarousel;
