import * as React from 'react';
import Carousel from './Carousel';
import LoadingSpinner from '../../loading-spinner';
import { getSocialProofPosts, ISocialProofApiPost } from '../../../api/social-proof-api';
import { buildSocialProofPosts, ISocialProofPosts } from './SocialProofPostBuilder';
import { ISocialProofCardProps, SocialProofCard } from './SocialProofCard';
import * as analytics from '../../../utilities/analytics';
import { getSocialProofCarousel } from '../../../api/content-service-api';
import { FadeInOnScroll } from '../../transitions/FadeInOnScroll';
import { ITrackingPayload } from '../../../types/analytics';
import { ANALYTICS_CONSTANTS } from '../../../utilities/analytics';
import { SocialProofModal } from './SocialProofModal/SocialProofModal';

interface ISocialProofProps {
    fullStoryPageId: string;
    nextFocus: string;
}

interface ISocialProofState {
    isLoading: boolean;
    dialogOpen: boolean;
    dialogPostId: string;
    socialProofCardsLoaded: boolean;
    socialProofCardErrorList: string[];
    socialProofCards: {
        allIds: string[];
        byId: {
            [id: string]: ISocialProofCardProps;
        };
    };
}

class SocialProof extends React.Component<ISocialProofProps, ISocialProofState> {
    private _socialProofCardsLoadedCounter: number;
    private _socialProofCardErrorList: string[];

    constructor(props: ISocialProofProps) {
        super(props);

        this.state = {
            dialogOpen: false,
            dialogPostId: '',
            socialProofCardsLoaded: false,
            socialProofCardErrorList: [],
            isLoading: false,
            socialProofCards: { allIds: [], byId: {} },
        };

        this._socialProofCardsLoadedCounter = 0;
        this._socialProofCardErrorList = [];
        this._handleSocialProofCardLoad = this._handleSocialProofCardLoad.bind(this);
        this._handleSocialProofCardError = this._handleSocialProofCardError.bind(this);
    }

    public componentDidMount(): void {
        this._loadSocialProofData();
    }

    public render(): React.ReactNode {
        const { isLoading, dialogPostId, dialogOpen, socialProofCards } = this.state;

        if (
            (!isLoading && socialProofCards.allIds.length === 0) ||
            socialProofCards.allIds.length === this.state.socialProofCardErrorList.length
        ) {
            return null;
        }

        if (isLoading) {
            return <LoadingSpinner height={'384px'} />;
        } else {
            return (
                <div data-fs-section={`${this.props.fullStoryPageId}: Social Proof`}>
                    <h2 className="social-proof--heading">
                        A hassle-free{' '}
                        <span className="desktop-hidden tablet-hidden">
                            <br />
                        </span>{' '}
                        kind of happy
                        <a
                            href="https://www.instagram.com/explore/tags/showyourbow/"
                            target="_blank"
                            className="social-proof--heading-hash-tag"
                            rel="noreferrer"
                        >
                            #ShowYourBow
                        </a>
                    </h2>
                    {this._renderCarousel()}
                    <SocialProofModal
                        isVisible={dialogOpen}
                        card={this.state.socialProofCards.byId[dialogPostId]}
                        onClose={() => this.setState({ dialogOpen: false })}
                    />
                </div>
            );
        }
    }

    private _loadSocialProofData = (): void => {
        this.setState({ isLoading: true }, () => {
            getSocialProofCarousel().then((socialProofCarousel) => {
                const socialProofPosts = buildSocialProofPosts(socialProofCarousel);

                if (socialProofPosts.allIds.length == 0) {
                    this.setState({ isLoading: false });
                } else {
                    getSocialProofPosts(socialProofPosts.allIds).then((posts) => {
                        const socialProofCardsState = this._buildSocialProofCardsState(posts, socialProofPosts);
                        this.setState({ isLoading: false, socialProofCards: socialProofCardsState });
                    });
                }
            });
        });
    };

    private _buildSocialProofCardsState(
        posts: ISocialProofApiPost[],
        socialProofPosts: ISocialProofPosts
    ): ISocialProofState['socialProofCards'] {
        const SocialProofCardState = this.state.socialProofCards;
        SocialProofCardState.allIds = socialProofPosts.allIds;

        posts.forEach((post) => {
            SocialProofCardState.byId[post.postId] = post as ISocialProofCardProps;
            SocialProofCardState.byId[post.postId].validPost = true;
            SocialProofCardState.byId[post.postId].horizDirection = socialProofPosts.byId[post.postId].horizDirection;
            SocialProofCardState.byId[post.postId].vertDirection = socialProofPosts.byId[post.postId].vertDirection;
        });

        return SocialProofCardState;
    }

    private _handleSocialProofCardLoad(id: string) {
        this._socialProofCardsLoadedCounter++;

        if (this._socialProofCardsLoadedCounter === this.state.socialProofCards.allIds.length) {
            this.setState({ socialProofCardsLoaded: true, socialProofCardErrorList: this._socialProofCardErrorList });
        }
    }

    private _handleSocialProofCardError(id: string) {
        this._socialProofCardsLoadedCounter++;
        this._socialProofCardErrorList.push(id);

        if (this._socialProofCardsLoadedCounter === this.state.socialProofCards.allIds.length) {
            this.setState({ socialProofCardsLoaded: true, socialProofCardErrorList: this._socialProofCardErrorList });
        }
    }

    private _renderCarousel() {
        const validSocialProofIds: string[] = [];

        this.state.socialProofCards.allIds.forEach((id) => {
            if (this.state.socialProofCards.byId[id]) {
                if (
                    this.state.socialProofCards.allIds.indexOf(id) > -1 &&
                    this.state.socialProofCardErrorList.indexOf(id) === -1 &&
                    this.state.socialProofCards.byId[id].validPost
                ) {
                    validSocialProofIds.push(id);
                }
            }
        });

        return (
            <FadeInOnScroll>
                <Carousel
                    itemsLoaded={this.state.socialProofCardsLoaded}
                    itemCount={validSocialProofIds.length}
                    name="social-proof"
                    nextFocus={this.props.nextFocus}
                    onIndexChanged={() => {
                        analytics.track('IG Social Proof Slide View');
                        const name = 'IG Social Proof Slide View';
                        const event = ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA;
                        const payload: ITrackingPayload = {
                            event: event,
                            linkDetails: {
                                name: name,
                            },
                        };
                        analytics.trackEDDL(payload);
                    }}
                >
                    {validSocialProofIds.map((id: string, index: number) => (
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    dialogOpen: true,
                                    dialogPostId: id,
                                });
                                analytics.track('IG Social Proof Open Dialog');
                                const name = 'IG Social Proof Open Dialog';
                                const event = ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA;
                                const payload: ITrackingPayload = {
                                    event: event,
                                    linkDetails: {
                                        name: name,
                                    },
                                };
                                analytics.trackEDDL(payload);
                            }}
                            key={id}
                        >
                            <SocialProofCard
                                {...this.state.socialProofCards.byId[id]}
                                onLoad={this._handleSocialProofCardLoad}
                                onError={this._handleSocialProofCardError}
                                itemIndex={index}
                            />
                        </div>
                    ))}
                </Carousel>
            </FadeInOnScroll>
        );
    }
}

export default SocialProof;
