import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import SVGs from '../../buying-online/utils/svgs';
import { useLocalCarsStore } from '../../../context/localCarsStore';
import { CarTilesCarousel } from '../../../components/carousels/car-tiles/CarTilesCarousel';
import { LocalCarsPlaceholderCarousel } from './LocalCarsPlaceholderCarousel';
import { trackEDDL } from '../../../utilities/analytics';

export const LocalCars = () => {
    const {
        localVehicles,
        setLocalVehicles,
        isLocalVehiclesLoading,
        favoriteVehicles,
        setFavoriteVehicles,
        addVehicleToFavorites,
        removeVehicleFromFavorites,
        getCarImages,
        setCiam,
        zipCode,
    } = useLocalCarsStore(
        useShallow((state) => ({
            localVehicles: state.localVehicles,
            setLocalVehicles: state.setLocalVehicles,
            isLocalVehiclesLoading: state.isLocalVehiclesLoading,
            favoriteVehicles: state.favoriteVehicles,
            setFavoriteVehicles: state.setFavoriteVehicles,
            addVehicleToFavorites: state.addVehicleToFavorites,
            removeVehicleFromFavorites: state.removeVehicleFromFavorites,
            getCarImages: state.getCarImages,
            setCiam: state.setCiam,
            zipCode: state.zipCode,
        }))
    );

    useEffect(() => {
        setCiam();
        setLocalVehicles();
        setFavoriteVehicles();
    }, [setCiam, setLocalVehicles, setFavoriteVehicles]);

    const openStoreSelectionModal = () => {
        const detail = { stateOrZip: zipCode };

        // Analytics
        trackEDDL({
            event: 'storeChange',
            linkDetails: {
                name: zipCode,
                position: 'Shop cars near you',
            },
        });

        // Open modal
        const event = new CustomEvent('kmxOpenStoreSelectionModal', { detail });
        document.documentElement.dispatchEvent(event);
    };

    const renderCarousel = () => {
        return isLocalVehiclesLoading ? (
            <LocalCarsPlaceholderCarousel isLoading={isLocalVehiclesLoading} />
        ) : localVehicles.length > 0 ? (
            <CarTilesCarousel
                carouselName="local-cars-carousel"
                vehicleTileList={localVehicles}
                favoriteVehicles={favoriteVehicles}
                onFavorite={addVehicleToFavorites}
                onUnfavorite={removeVehicleFromFavorites}
                getCarImages={getCarImages}
                nextFocus="online-offer"
            />
        ) : (
            <LocalCarsPlaceholderCarousel isLoading={isLocalVehiclesLoading} />
        );
    };

    return (
        <>
            <div id="local-cars-container">
                <div className="local-cars--heading">
                    <h2>Shop cars near me</h2>
                    <button
                        type="button"
                        className="zip-code"
                        onClick={openStoreSelectionModal}
                        aria-haspopup="true"
                        aria-label="Change your CarMax store"
                    >
                        {SVGs.locationIcon} {zipCode}
                    </button>
                </div>
                {renderCarousel()}
            </div>
        </>
    );
};
