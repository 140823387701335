import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import './snack-bar.scss';

interface ISnackBarProps {
    cancelAction: () => void;
    className: string;
    icon?: JSX.Element;
    textLabel: string;
}

export const SnackBar = (props: ISnackBarProps) => {
    const { cancelAction, className, icon, textLabel } = props;
    const CLOSE_TIMEOUT = 3000;
    const SNACKBAR_ANIMATION_DURATION = CLOSE_TIMEOUT + 750;
    const SNACKBAR_ACTIVE_CLASS = 'hp-snackbar--active';
    const snackBarRef = React.useRef<HTMLDivElement | null>(null);
    const bodyRef = React.useRef<HTMLElement>(document.body);

    const openSnackbar = () => {
        const sbRef = snackBarRef.current;
        sbRef && !sbRef.classList.contains(SNACKBAR_ACTIVE_CLASS) && sbRef.classList.add(SNACKBAR_ACTIVE_CLASS);
    };

    const closeSnackbar = () => {
        const sbRef = snackBarRef.current;
        sbRef && sbRef.classList.contains(SNACKBAR_ACTIVE_CLASS) && sbRef.classList.remove(SNACKBAR_ACTIVE_CLASS);
        sbRef && sbRef.getAttribute('aria-live') && sbRef.setAttribute('aria-live', 'off');
    };

    const keydownHandler = React.useCallback(
        (event) => {
            const { key } = event as KeyboardEvent;
            if (['Escape', 'Esc'].includes(key)) {
                cancelAction();
            }
        },
        [cancelAction]
    );

    React.useEffect(() => {
        setTimeout(() => {
            openSnackbar();
        }, 250);
        document.addEventListener('keydown', keydownHandler);
        return () => document.removeEventListener('keydown', keydownHandler);
    }, []);

    React.useEffect(() => {
        const id = setTimeout(() => {
            closeSnackbar();
        }, CLOSE_TIMEOUT);
        return () => {
            clearTimeout(id);
        };
    }, [CLOSE_TIMEOUT]);

    React.useEffect(() => {
        const id = setTimeout(() => {
            cancelAction();
        }, SNACKBAR_ANIMATION_DURATION);
        return () => {
            clearTimeout(id);
        };
    }, [SNACKBAR_ANIMATION_DURATION, cancelAction]);

    const snackbar = (
        <div
            test-id="snackbar"
            className={classNames(className, 'hp-snackbar')}
            aria-live="polite"
            aria-atomic="true"
            ref={snackBarRef}
        >
            {icon && <div className="icon">{icon}</div>}
            <div className="label">{textLabel}</div>
        </div>
    );

    return ReactDOM.createPortal(snackbar, bodyRef.current);
};
