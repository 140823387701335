import CarTile from '@kmx/shared-components/car-tile/CarTile';
import { IVehicleTile } from '../../../../types/vehicleTile';
import { vehicleIsUnavailable, buildStatusText } from '../../../../utilities/vehicleTileBuilder';
import { mapVehicleTileToCar } from './carTileUtils';
import { useEffect, useState, useContext } from 'react';
import Icons from '../../../../utilities/icons';
import { getCarImages } from '../../../../api/getCarImages';
import { FBSContext } from './../../../../context/';
import { IFBSDecision } from '../../../../api/types/finance';
import * as analytics from '../../../../utilities/analytics';
import TileKebabMenuItemSnackbar from '../tile-kebab-menu/TileKebabMenuItemSnackbar';
import { useLocalCarsStore } from '../../../../context/localCarsStore';
import { useShallow } from 'zustand/react/shallow';
import { ANALYTICS_CONSTANTS } from '../../../../utilities/analytics';
import { ITrackingPayload } from '../../../../types/analytics';

interface ICarTileWrapperProps {
    vehicle: IVehicleTile;
    updatedViewedTiles?: (VehicleTile: IVehicleTile) => any;
    enableRemove: boolean;
    isWaitingForFavoritesResponse: boolean;
}

export const CarTileWrapper = ({ vehicle, updatedViewedTiles, enableRemove }: ICarTileWrapperProps) => {
    const car = mapVehicleTileToCar(vehicle);
    const [isFavoriteInUI, setIsFavoriteInUI] = useState<boolean>(false);
    const [snackbar, setSnackBar] = useState<{ icon: any; text: string; isOpen: boolean }>({
        icon: undefined,
        text: '',
        isOpen: false,
    });
    const ribbonText = buildStatusText(vehicle);
    const fbsContext = useContext(FBSContext);

    const mapDecisionToFbsApplication = (decision: IFBSDecision) => {
        const {
            applicationUrl,
            fbsDecision: { downPayment, expirationDateFormatted, stateAbbreviation, isSoftPull, tradeInBreakdown },
        } = decision;
        return {
            downPayment: downPayment,
            stateAbbreviation: stateAbbreviation,
            stationUrl: applicationUrl,
            expirationDateFormatted: expirationDateFormatted,
            isSoftPull: isSoftPull,
            tradeInBreakdown,
        };
    };

    const { favoriteVehicles, addVehicleToFavorites, removeVehicleFromFavorites } = useLocalCarsStore(
        useShallow((state) => ({
            favoriteVehicles: state.favoriteVehicles,
            addVehicleToFavorites: state.addVehicleToFavorites,
            removeVehicleFromFavorites: state.removeVehicleFromFavorites,
        }))
    );

    const decision = fbsContext.fbsData.find((d) => d.stockNumber === vehicle.stockNumber);
    let fbsApplication: object | undefined;
    if (decision) {
        fbsApplication = mapDecisionToFbsApplication(decision);
    }

    const KEBAB_MENU = [
        {
            text: `${isFavoriteInUI ? 'Remove from' : 'Add to'} favorites`,
            icon: Icons.favoriteHeart,
            onClick: () => {
                handleHeartClick();
            },
            className: `tile-menu-item--favorite ${isFavoriteInUI ? 'saved' : ''}`,
        },
        {
            text: 'Share',
            icon: Icons.share,
            onClick: () => {
                handleShareClick();
                analytics.track('car-tile-share');
                const name = 'share';
                const event = ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA;
                const payload: ITrackingPayload = {
                    event: event,
                    linkDetails: {
                        name: name,
                        position: vehicle.stockNumber.toString(),
                    },
                };
                analytics.trackEDDL(payload);
            },
            className: 'tile-menu-item--share',
        },
    ];

    const CRITICAL_MENU_ITEM = {
        text: 'Remove',
        icon: Icons.remove,
        onClick: () => {
            handleRemoveVehicleTile();
            analytics.track('car-tile-remove');
            const name = 'remove';
            const event = ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA;
            const payload: ITrackingPayload = {
                event: event,
                linkDetails: {
                    name: name,
                    position: vehicle.stockNumber.toString(),
                },
            };
            analytics.trackEDDL(payload);
        },
        className: 'tile-menu-item--remove',
    };

    useEffect(() => {
        setIsFavoriteInUI(favoriteVehicles.includes(vehicle.stockNumber));
    }, [favoriteVehicles, vehicle.stockNumber]);

    const handleHeartClick = () => {
        setIsFavoriteInUI(isFavoriteInUI);
        analytics.track('car-tile-favorite');

        if (isFavoriteInUI) {
            const payload: ITrackingPayload = {
                event: ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA,
                linkDetails: {
                    name: 'remove from favorites',
                    position: vehicle.stockNumber.toString(),
                },
            };
            analytics.trackEDDL(payload);
            removeVehicleFromFavorites(vehicle.stockNumber);
        } else {
            analytics.track(`car-tile-${isFavoriteInUI ? 'favorite' : 'remove'}`);
            const payload: ITrackingPayload = {
                event: ANALYTICS_CONSTANTS.EVENTS.CLICK.SAVE_RECOMMENDED_CAR,
                linkDetails: {
                    name: 'save car',
                    position: vehicle.stockNumber.toString(),
                },
            };
            analytics.trackEDDL(payload);
            addVehicleToFavorites(vehicle.stockNumber);
        }
    };

    // Wrap the get request so we can indirectly provide the built in image carousel with our context env.
    const getImagesWithContext = (
        stockNumber: any,
        successCallback: (response: any) => void,
        errorCallback: (error: any) => void
    ) => {
        return getCarImages(stockNumber, successCallback, errorCallback);
    };

    const handleRemoveVehicleTile = async () => {
        if (updatedViewedTiles) {
            updatedViewedTiles(vehicle);
        }
        removeVehicleFromFavorites(vehicle.stockNumber, true);
    };

    const handleShareClick = async () => {
        if (navigator.share) {
            navigator
                .share({
                    title: `${document.title}.`,
                    text: `Check out ${document.title}.`,
                    url: `${window.location.origin}/car/${vehicle.stockNumber}`,
                })
                .then(() => console.log('Successful Car Tile Share'))
                .catch((error) => console.log('Error Sharing Car Tile', error));
        } else {
            await navigator.clipboard.writeText(`${window.location.origin}/car/${vehicle.stockNumber}`);
            setSnackBar({ icon: Icons.share, isOpen: true, text: 'Link copied to your clipboard' });
        }
    };

    return (
        <>
            <CarTile
                car={car}
                disableScrollNav={false}
                imageUrl={vehicle.imageLink}
                showFavorites={true}
                isDisabled={vehicleIsUnavailable(vehicle.statusCode, vehicle.isSaleable)}
                ribbonStyle="standard"
                ribbonText={ribbonText}
                isFavorite={isFavoriteInUI}
                menuItems={KEBAB_MENU}
                criticalMenuItem={enableRemove ? CRITICAL_MENU_ITEM : undefined}
                onFavorite={handleHeartClick}
                onUnfavorite={handleHeartClick}
                getCarImages={getImagesWithContext}
                fbsApplication={fbsApplication}
                fbsDecision={decision?.fbsDecision}
                downPayment={decision?.fbsDecision.downPayment}
                recommendationType={vehicle.recommendationType}
            />

            <TileKebabMenuItemSnackbar
                icon={snackbar.icon}
                isOpen={snackbar.isOpen}
                textLabel={snackbar.text}
                cancelAction={() => setSnackBar({ icon: undefined, isOpen: false, text: '' })}
            />
        </>
    );
};
