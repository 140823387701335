import { ITrackingPayload } from '../types/analytics';

declare global {
    interface Window {
        _satellite: any;
    }
}

declare global {
    interface Window {
        adobeDataLayer: any;
        digitalData: any;
    }
}

export const ANALYTICS_CONSTANTS = {
    EVENTS: {
        CLICK: {
            CTA: 'ctaClick',
            SAVE_RECOMMENDED_CAR: 'recommendedSaveCarClick',
        },
        PAGE: {
            LOAD: 'pageLoad',
            STATE: 'pageState',
        },
    },
    PAGE_STATE_LOAD_TIME: 2000,
};

export const track = (eventName: string, delay?: boolean) => {
    if (delay) {
        let trackingTriggered = false;
        const analyticsInterval = window.setInterval(() => {
            if ((window as any)._satellite) {
                clearInterval(analyticsInterval);
                if (!trackingTriggered) {
                    trackingTriggered = true;
                    (window as any)._satellite.track(eventName);
                }
            }
        }, 1);
    } else {
        if ((window as any)._satellite) {
            (window as any)._satellite.track(eventName);
        }
    }
};

export const trackEDDL = (payload: ITrackingPayload): void => {
    if (payload.event == null) {
        payload.event = ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA;
    }
    console.log('Home Microsite Tagging Payload - ', JSON.stringify(payload));
    // "adobeDataLayer" will appear in the console if the site in questions is using "EDDL"
    if (window.adobeDataLayer) {
        window.adobeDataLayer.push(payload);
    } else {
        console.log('adobeDataLayer is undefined. Data did not track.');
    }
};
