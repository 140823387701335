import * as ReactDOM from 'react-dom';
import onDomContentLoaded from '../../utilities/onDomContentLoaded';
import BudgetCalculatorContainer from './budget-calculator/BudgetCalculatorContainer';
import InstantOffersLoader from '../../components/instant-offers/InstantOffersLoader';
import { instantOffersSectionId } from '../../components/instant-offers/utils/constants';
import SocialProofContainer from '../../components/carousels/social-proof/SocialProofContainer';
import ResearchArticlesContainer from '../../components/carousels/research-articles/ResearchArticlesContainer';
import StatePricingDisclaimerContainer from '../../components/StatePricingDisclaimerContainer';
import ErrorBoundary from '../../components/ErrorBoundary';
import HeroContainer from './hero/HeroContainer';
import HeroRefactor from './HeroRefactor';
import * as nearestStoreLinkBuilder from './utilities/nearestStoreLinkBuilder';
import { isReturnHomePageVisitor, trackFirstTimeHomePageVisitor } from './utilities/helpers';
import { FULL_STORY_HOME_PAGE_ID } from './utilities/constants';
import '../../../scss/pages/home/main.scss';
import { ServerSideExperimentProvider } from './campaigns/ServerSideExperimentContext';
import { FBSProvider } from '../../context';
import ModalHandler from '@kmx/shared-components/modalverse/ModalHandler';
import { isAuthenticated } from '../../utilities/cookieHelper';
import { LocalCarsLoader } from './local-cars/LocalCarsLoader';
import { RecentSearchesContainer } from './homebase-optimization-test/recent-searches/RecentSearchesContainer';
import { fetchAsyncData, getComponentAndData } from './HeroRefactor/services/services';
import { useHomeStore } from './HeroRefactor/Store';
import { YouMightLikeContainer } from './homebase-optimization-test/YouMightLikeContainer';
import LinkFarm from './link-farm';
import { FadeInOnScroll } from '../../components/transitions/FadeInOnScroll';
import { ITrackingPayload } from '../../types/analytics';
import { trackEDDL, track, ANALYTICS_CONSTANTS } from '../../utilities/analytics';

function startup() {
    fetchAsyncData().then((experiences) => {
        const experienceData = getComponentAndData(experiences);

        useHomeStore.getState().setInitialValues({
            experiences: experiences as unknown[],
            component: experienceData.name,
            experienceData,
            isLoading: false,
        });
    });

    nearestStoreLinkBuilder.buildAllLinks();
    const heroContainer = document.getElementById('hero') as HTMLElement;
    const heroConainerNew = document.getElementById('hero-new') as HTMLElement;
    if (!featureFlags.enableHomeBaseRefactor && (isReturnHomePageVisitor() || isAuthenticated())) {
        if (heroContainer) {
            ReactDOM.render(
                <ErrorBoundary>
                    <ModalHandler>
                        <ServerSideExperimentProvider>
                            <FBSProvider>
                                <HeroContainer fullStoryPageId={FULL_STORY_HOME_PAGE_ID} />
                            </FBSProvider>
                        </ServerSideExperimentProvider>
                    </ModalHandler>
                </ErrorBoundary>,
                heroContainer
            );
        }
    } else {
        setTimeout(function () {

           track('HP Brand Hero Init', true);
        }, 1000);
        trackFirstTimeHomePageVisitor();
    }

    if (featureFlags.enableHomeBaseRefactor) {
        ReactDOM.render(
            <ErrorBoundary>
                <ModalHandler>
                    <ServerSideExperimentProvider>
                        <FBSProvider>
                            <HeroRefactor />
                        </FBSProvider>
                    </ServerSideExperimentProvider>
                </ModalHandler>
            </ErrorBoundary>,
            heroConainerNew
        );
    }

    const recentSearchesContainer = document.getElementById('recent-searches-container');

    if (recentSearchesContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <RecentSearchesContainer />
            </ErrorBoundary>,
            recentSearchesContainer
        );
    }

    const youMightLikeContainer = document.getElementById('you-might-like-container');

    if (youMightLikeContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <YouMightLikeContainer />
            </ErrorBoundary>,
            youMightLikeContainer
        );
    }

    const budgetCalculatorContainer = document.getElementById('budget-calculator-section');

    if (budgetCalculatorContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <ServerSideExperimentProvider>
                    <FBSProvider>
                        <BudgetCalculatorContainer
                            fullStoryPageId={FULL_STORY_HOME_PAGE_ID}
                            financeCalculatorName="HP Calculator"
                        />
                    </FBSProvider>
                </ServerSideExperimentProvider>
            </ErrorBoundary>,
            budgetCalculatorContainer
        );
    }

    const localCars = document.getElementById('local-cars');

    if (localCars) {
        ReactDOM.render(
            <ErrorBoundary>
                <ModalHandler>
                    <LocalCarsLoader />
                </ModalHandler>
            </ErrorBoundary>,
            localCars
        );
    }

    const socialProofContainer = document.getElementById('social-proof');

    if (socialProofContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <SocialProofContainer
                    fullStoryPageId={FULL_STORY_HOME_PAGE_ID}
                    nextFocus="#research-articles-component"
                />
            </ErrorBoundary>,
            socialProofContainer
        );
    }

    const instantOffersContainer = document.getElementById(instantOffersSectionId);

    if (instantOffersContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <InstantOffersLoader />
            </ErrorBoundary>,
            instantOffersContainer
        );
    }

    const researchArticleContainer = document.getElementById('research-articles-carousel-container');

    if (researchArticleContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <FadeInOnScroll>
                    <ResearchArticlesContainer fullStoryPageId={FULL_STORY_HOME_PAGE_ID} nextFocus="#homepage-links" />
                </FadeInOnScroll>
            </ErrorBoundary>,
            researchArticleContainer
        );
    }

    const statePricingDisclaimerContainer = document.getElementById('state-pricing-disclaimer');

    if (statePricingDisclaimerContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <StatePricingDisclaimerContainer fullStoryPageId={FULL_STORY_HOME_PAGE_ID} />
            </ErrorBoundary>,
            statePricingDisclaimerContainer
        );
    }

    const linkFarmContainer = document.getElementById('link-farm-tabs');
    if (linkFarmContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <LinkFarm fullStoryPageId={FULL_STORY_HOME_PAGE_ID} />
            </ErrorBoundary>,
            linkFarmContainer
        );
    }
}

// Function to be called when a card's title is clicked
const handleEddlOnClick = (name: string, position: string) => {
    trackEDDL({
        event: ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA,
        linkDetails: {
            name,
            position,
        },
    });
};

const registerEventListeners = () => {
    // Get Apps Icons
    const appsIcons: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('a.mobile-apps--badge-link');
    appsIcons.forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();
            handleEddlOnClick(element.getAttribute('data-type') || '', 'apps');
            window.open(element.href, '_blank');
        });
    });
};

onDomContentLoaded(() => {
    startup();
    registerEventListeners();
});
